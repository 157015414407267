let initialState = {isCancelled: false,scratchAmount:0,advanceAmount:0,isFullPaid: false,remarks: '',IP:''}


export default function orderReducer(state = initialState,action){
    switch(action.type){
        case 'UPDATE_SELECTED_CAR':
            return{
                ...state,
                selectedCar: action.data
            }
        case 'UPDATE_SELECTED_VENDOR':
            return{
                ...state,
                selectedVendor: action.data
            }
        case 'UPDATE_PAYMENT_ID':
            return{
                ...state,
                paymentId: action.data
            }
        case 'UPDATE_BID_ID':
            return{
                ...state,
                bidId: action.data.bidId,
                accountSeqId: action.data.accountSeqId
            }
        case 'BID_SUBMITTED':
            return {
                ...state,
                isSubmitted: true
            }
        case 'FLUSH_ORDER_STATE':
            return Object.assign({},initialState);
        
        case 'CANCEL_RIDE':
            return Object.assign({},state,{ isCancelled: true })

        case 'UPDATE_SCRATCH_AMOUNT':
            return{
                ...state,
                scratchAmount: action.data
            }
        case 'UPDATE_ADVANCE_AMOUNT':
            return{
                ...state,
                advanceAmount: action.data
            }
        case 'FULL_PAID':
            return{
                ...state,
                isFullPaid: true
            }
        case 'UPDATE_REMARKS':
            return{
                ...state,
                remarks: action.data
            }
        case 'UPDATE_IP':
            return{
                ...state,
                IP: action.data
            }

        default: 
            return state
    }
}

