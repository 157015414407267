import {createStore,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import logger from 'redux-logger';



export default function configureStore(persistedState) {
  return createStore(
    rootReducer,
    persistedState,
    applyMiddleware(thunk,logger)
  );
}