import Moment from 'moment';
let today = Moment();
let minutes = 30 - (today.minute() % 30);
// let leadTime = this.props.ride.leadTime;
// let leadTime = 2;
let minStartDate = Moment().add(minutes, 'm').seconds('0');

let initialState = {
    pickup: {
        loc: "",
    },
    dest: {
        loc: "",
    },
    stops: [],
    leadTime: 0,
    measureLookupId: '100010001',
    distance: 0,
    duration: 0,
    startDate: minStartDate,
    endDate: '',
    rentalType: 1,
    isChauffeurDriven: false,
    isSelfDriven:true,
    isAirport:false,
    isRental:false,
    startTime: Moment(minStartDate).format("HH:mm:ss"),
    endTime: '',
    hourlyPackage: '8 hrs, 80kms',
    nearby:false,
    currentDateTime: '',
    selfDriveCities: [],
    airportPickupCities: [],
    airportDropCities: [],
    hr4Cities: [],
    hr8Cities: [],
    hr12Cities: [],
    oneWayCities: [],
    roundTripCities: [],
    airportType: 'Airport Pickup'
}
let newStops = [];

export default function rideReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_FIRST_STOP':
            // if (state.stops.length === 0) {
            //     return {
            //         ...state,
            //         stops: [
            //             {
            //                 seqId: 1,
            //                 city: action.data.city,
            //                 name: action.data.name,
            //                 latitude: action.data.latitude,
            //                 longitude: action.data.longitude
            //             }
            //         ]
            //     }
            // }
            // else {
                newStops = state.stops;
                newStops[0] = { 
                    seqId: 1, 
                    city: action.data.city, 
                    name: action.data.name, 
                    latitude: action.data.latitude, 
                    longitude: action.data.longitude 
                }
                // return {
                //     ...state,
                //     stops: state.stops.map(
                //         (stop, i) => i ==== 0 ? { seqId: 1, city: action.data.city, name: action.data.name, latitude: action.data.latitude, longitude: action.data.longitude }
                //             : stop
                //     )
                // }
                return Object.assign({},state,{stops: newStops});
            // }
        case 'ADD_STOP':
            if (state.measureLookupId === '100010001') {
                //if roundTrip true
                
                return {
                    ...state,
                    stops: [
                        ...state.stops,
                        {
                            ...action.data,
                            seqId: state.stops.length + 1,
                        }
                    ]
                    // stops: [
                    //     ...state.stops.slice(0, state.stops.length - 1),
                    //     {
                    //         seqId: state.stops.length,
                    //         name: action.data.name,
                    //         latitude: action.data.latitude,
                    //         longitude: action.data.longitude
                    //     },
                    //     ...state.stops.slice(state.stops.length - 1).map((stop) => ({ seqId: stop.seqId + 1, name: stop.name, latitude: stop.latitude, longitude: stop.longitude }))
                    // ]
                }
            }

            return {
                ...state,
                stops: [
                    ...state.stops,
                    {
                        ...action.data,
                        seqId: state.stops.length + 1,
                    }
                ]
            }

        case 'EDIT_STOP':
            return {
                ...state,
                stops: state.stops.map(function (stop, i) {
                    
                    if (i === action.id) {
                        return { seqId: stop.seqId, city: action.data.city, name: action.data.name, latitude: action.data.latitude, longitude: action.data.longitude };
                    }
                    else {
                        return stop;
                    }
                }
                )
            }

        case 'ADD_STOP_AT_INDEX':
            
            return {
                ...state,
                stops: [
                    ...state.stops.slice(0, action.index),
                    {
                        seqId: action.index + 1,
                        ...action.data
                    },
                    ...state.stops.slice(action.index).map((stop) => ({ ...stop, seqId: stop.seqId + 1 }))
                ]
            }
        
        case 'PUSH_STOP_AT_INDEX':
            newStops = state.stops;
            newStops[action.index] = action.data;
            
            return {
                ...state,
                stops: newStops
            }

        case 'REMOVE_LAST_STOP':
            return {
                ...state,
                stops: [
                    ...state.stops.slice(0, state.stops.length - 1)
                ]
            }
        case 'UPDATE_PICKUP_LOC':
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    loc: action.data.name,
                    locLat: action.data.locLat,
                    locLng: action.data.locLng
                }
            }
        case 'UPDATE_PICKUP_CITY':
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    city: action.data.city,
                    cityLat: action.data.cityLat,
                    cityLng: action.data.cityLng,
                }
            }
        case 'UPDATE_DEST_LOC':
            return {
                ...state,
                dest: {
                    ...state.dest,
                    loc: action.data.name,
                    locLat: action.data.locLat,
                    locLng: action.data.locLng
                }
            }
        case 'UPDATE_DEST_CITY':
            return {
                ...state,
                dest: {
                    ...state.dest,
                    city: action.data.city,
                    cityLat: action.data.cityLat,
                    cityLng: action.data.cityLng,
                }
            }
        case 'UPDATE_CITY_OF_STOP':
            return {
                ...state,
                stops: state.stops.map(
                    (stop, i) => i === action.stopId ? { ...stop, city: action.data.city, cityLat: action.data.cityLat, cityLng: action.data.cityLng } : stop
                )
            }
        case 'UPDATE_LEAD_TIME':
            return {
                ...state,
                leadTime: action.data
            }
        case 'UPDATE_TRIP_TYPE':
            return {
                ...state,
                measureLookupId: action.id
            }
        case 'UPDATE_RENTAL_TYPE':
            return {
                ...state,
                rentalType: action.data
            }
        case 'UPDATE_START_DATE':
            return {
                ...state,
                startDate: action.data
            }
        case 'UPDATE_END_DATE':
            return {
                ...state,
                endDate: action.data
            }
        case 'UPDATE_START_TIME':
            return {
                ...state,
                startTime: action.data
            }
        case 'UPDATE_END_TIME':
            return {
                ...state,
                endTime: action.data
            }
        case 'UPDATE_RIDE_DISTANCE':
            return {
                ...state,
                distance: action.data
            }
        case 'UPDATE_RIDE_DURATION':
            return {
                ...state,
                duration: action.data
            }
        case 'CHAUFFEUR_DRIVEN_TRUE':
            return {
                ...state,
                isChauffeurDriven:true,
                isSelfDriven:false,
                isRental: false,
                isAirport: false,
            }
        case 'SELF_DRIVEN_TRUE':
            return {
                ...state,
                isChauffeurDriven:false,
                isSelfDriven:true,
                isRental: false,
                isAirport: false,
            }
        case 'AIRPORT_TRUE':
            return {
                ...state,
                isChauffeurDriven:false,
                isSelfDriven:false,
                isRental: false,
                isAirport: true,
            }
        case 'RENTAL_TRUE':
            return {
                ...state,
                isChauffeurDriven:false,
                isSelfDriven:false,
                isRental: true,
                isAirport: false,
            }
        case 'UPDATE_HOURLY_PACKAGE':
            return {
                ...state,
                hourlyPackage: action.data
            }
        case 'FLUSH_RIDE_STATE':
            newStops = [];
            return Object.assign({},initialState,{stops: newStops});
        case 'NEARBY_CITY_TRUE':
            return {
                ...state,
                nearby:true,
            }
        case 'NEARBY_CITY_FALSE':
            return {
                ...state,
                nearby:false,
            }
        case 'UPDATE_CURRENT_DATE_TIME':
            return {
                ...state,
                currentDateTime: action.data
            }
        case 'UPDATE_STATIC_CITIES':
            return {
                ...state,
                selfDriveCities: action.data.selfDrive,
                airportPickupCities: action.data.airportPickup,
                airportDropCities: action.data.airportDrop,
                hr4Cities: action.data.hr4,
                hr8Cities: action.data.hr8,
                hr12Cities: action.data.hr12,
                oneWayCities: action.data.oneWay,
                roundTripCities: action.data.roundTrip
            }
        case 'UPDATE_AIRPORT_TYPE':
            return {
                ...state,
                airportType: action.data
            }
        default:
            return state

    }
}