import { combineReducers } from 'redux';
// import ride from './rideReducer';
import cars from './carsReducer';
import coupons from './couponReducer';
import user from './userReducer';
import ride from './rideReducer';
import trip from './tripReducer';
import order from './orderReducer';
import myChoize from './myChoizeReducer';
import placeHolder from './placeHolderReducer';

const rootReducer = combineReducers({
  ride,
  cars,
  coupons,
  user,
  order,
  trip,
  myChoize,
  placeHolder
});

export default rootReducer;
