

let initialState = {
    chauffeur: [],
    selfDrive: [],
    vendors: [],
    airport: [],
    rental: [],
    isChauffeurLoading: true,
    isSelfDriveLoading: true,
    isChauffeurAvailable: false,
    isSelfDriveAvailable: false,
    isVendorsAvailable: false,
    isVendorsLoading: true,
    isRentalLoading:true,
    isRentalAvailable:false,
    isAirportLoading:true,
    isAirportAvailable:true,
    rating:[],
}


export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_CARS':
            return {
                ...state,
                chauffeur: action.data
            }
        case 'UPDATE_SELFDRIVE_CARS':
            return {
                ...state,
                selfDrive: action.data
            }

        case 'UPDATE_VENDORS':
            return {
                ...state,
                vendors: action.data
            }  

        case 'UPDATE_AIRPORT_CARS':
            return {
                ...state,
                airport: action.data
            }
        case 'UPDATE_RENTAL_CARS':
            return {
                ...state,
                rental: action.data
            }
        case 'CHAUFFEUR_CARS_LOADING_TRUE':
            return {
                ...state,
                isChauffeurLoading: true
            }
        case 'CHAUFFEUR_CARS_LOADING_FALSE':
            return {
                ...state,
                isChauffeurLoading: false
            }
        case 'SELF_DRIVE_LOADING_TRUE':
            return {
                ...state,
                isSelfDriveLoading: true
            }
        case 'SELF_DRIVE_LOADING_FALSE':
            return {
                ...state,
                isSelfDriveLoading: false
            }
        case 'CHAUFFEUR_CARS_AVAILABLE_TRUE':
            return {
                ...state,
                isChauffeurAvailable: true
            }
        case 'CHAUFFEUR_CARS_AVAILABLE_FALSE':
            return {
                ...state,
                isChauffeurAvailable: false
            }
        case 'SELF_DRIVE_AVAILABLE_TRUE':
            return {
                ...state,
                isSelfDriveAvailable: true
            }
        case 'SELF_DRIVE_AVAILABLE_FALSE':
            return {
                ...state,
                isSelfDriveAvailable: false
            }
        case 'VENDORS_AVAILABLE_TRUE':
            return {
                ...state,
                isVendorsAvailable: true
            }
        case 'VENDORS_AVAILABLE_FALSE':
            return {
                ...state,
                isVendorsAvailable: false
            }
        case 'VENDORS_LOADING_TRUE':
            return {
                ...state,
                isVendorsLoading: true
            }
        case 'VENDORS_LOADING_FALSE':
            return {
                ...state,
                isVendorsLoading: false
            }
        case 'AIRPORT_CARS_LOADING_TRUE':
            return {
                ...state,
                isAirportLoading: true
            }
        case 'AIRPORT_CARS_LOADING_FALSE':
            return {
                ...state,
                isAirportLoading: false
            }
        case 'AIRPORT_CARS_AVAILABLE_TRUE':
            return {
                ...state,
                isAirportAvailable: true
            }
        case 'AIRPORT_CARS_AVAILABLE_FALSE':
            return {
                ...state,
                isAirportAvailable: false
            }
        case 'RENTAL_CARS_LOADING_TRUE':
            return {
                ...state,
                isRentalLoading: true
            }
        case 'RENTAL_CARS_LOADING_FALSE':
            return {
                ...state,
                isRentalLoading: false
            }
        case 'RENTAL_CARS_AVAILABLE_TRUE':
            return {
                ...state,
                isRentalAvailable: true
            }
        case 'RENTAL_CARS_AVAILABLE_FALSE':
            return {
                ...state,
                isRentalAvailable: false
            }
            case 'SHOW_RATING':
            return {
                ...state,
                rating: action.data
            }
        
        case 'FLUSH_CARS_STATE':
            return Object.assign({},initialState);
        default:
            return state
    }

}