
let initialState = {
    all: [],
    selected: { name: '' },
    isCouponsAvailable: true,
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_COUPONS':
            return {
                ...state,
                all: action.data
            }
        case 'COUPONS_AVAILABLE_FALSE':
            return {
                ...state,
                isCouponsAvailable: false
            }
        case 'UPDATE_SELECTED_COUPON':
            return {
                ...state,
                selected: action.data
            }
        case 'FLUSH_COUPON_STATE':
            return Object.assign({}, initialState);
        default:
            return state
    }

}