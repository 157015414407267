let initialState = {
    pickCity: '',
    dropCity: '',
    pickAddress: '',
    dropAddress: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    rentalType: '',
    type: '',
    vendor: '',
    carCategotyId: null,
    carSubCategoryId: null
}

export default function placeHolderReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_PLACEHOLDER_DATA':
            return {
                ...state,
                pickCity: action.data.pickCity,
                dropCity: action.data.dropCity,
                pickAddress: action.data.pickAddress,
                dropAddress: action.data.dropAddress,
                startDate: action.data.startDate,
                startTime: action.data.startTime,
                endDate: action.data.endDate,
                endTime: action.data.endTime,
                rentalType: action.data.rentalType,
                type: action.data.type,
                vendor: action.data.vendor,
                carCategotyId: action.data.carCategotyId,
                carSubCategoryId: action.data.carSubCategoryId
            }
        default:
            return state

    }
}