// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-cars-list-js": () => import("./../../../src/pages/carsList.js" /* webpackChunkName: "component---src-pages-cars-list-js" */),
  "component---src-pages-component-builder-js": () => import("./../../../src/pages/componentBuilder.js" /* webpackChunkName: "component---src-pages-component-builder-js" */),
  "component---src-pages-confirmed-js": () => import("./../../../src/pages/confirmed.js" /* webpackChunkName: "component---src-pages-confirmed-js" */),
  "component---src-pages-css-js": () => import("./../../../src/pages/css.js" /* webpackChunkName: "component---src-pages-css-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partnerWithUs.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-review-page-js": () => import("./../../../src/pages/reviewPage.js" /* webpackChunkName: "component---src-pages-review-page-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

