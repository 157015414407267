let initialState = { name: '', phone: '', email: '', isLoggedIn: false, fcmToken: '', profile: {}, licenseNo: '', idType: '', };


export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_USER':
            return Object.assign({}, state, action.data);
        case 'UPDATE_PROFILE':
            return {
                ...state,
                profile: action.data
            }
        case 'UPDATE_LICENSE':
            return Object.assign({}, state, { licenseNo: action.data.licenseNo, idType: action.data.idType, [action.data.idType]: action.data[action.data.idType] });
        case 'UPDATE_FCM_TOKEN':
            return Object.assign({}, state, { fcmToken: action.data });
        case 'LOG_IN_USER':
            return Object.assign({}, state, { isLoggedIn: true });
        case 'LOG_OUT_USER':
            return Object.assign({}, state, { isLoggedIn: false });
        case 'FLUSH_USER_STATE':
            return Object.assign({}, initialState);
        default:
            return state
    }
}