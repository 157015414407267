
let initialState = {
    all: [],
    isAvailable:true,
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_TRIPS':
            return {
                ...state,
                all: action.data
            }

            case 'TRIPS_AVAILABLE_FALSE':
            return {
                ...state,
                isAvailable: false
            }

        case 'TRIPS_AVAILABLE_TRUE':
            return {
                ...state,
                isAvailable: true
            }
        case 'TRIPS_LOADING_FALSE':
            return {
                ...state,
                isLoading: false
            }
        case 'TRIPS_LOADING_TRUE':
            return {
                ...state,
                isLoading: true
            }

        case 'FLUSH_TRIP_STATE':
            return Object.assign({},initialState);

        default:
            return state
    }

}