let initialState = {
    pickup: {},
    dest: {},
    stops: [],
}
let newStops = [];

export default function myChoizeReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_FIRST_STOP_MYCHOIZE':
                newStops = state.stops;
                newStops[0] = { 
                    seqId: 1, 
                    city: action.data.city, 
                    name: action.data.name, 
                    latitude: action.data.latitude, 
                    longitude: action.data.longitude 
                }
                return Object.assign({},state,{stops: newStops});

        case 'ADD_STOP_MYCHOIZE':
            if (state.measureLookupId === '100010001') {
                //if roundTrip true
                
                return {
                    ...state,
                    stops: [
                        ...state.stops.slice(0, state.stops.length - 1),
                        {
                            seqId: state.stops.length,
                            name: action.data.name,
                            latitude: action.data.latitude,
                            longitude: action.data.longitude
                        },
                        ...state.stops.slice(state.stops.length - 1).map((stop) => ({ seqId: stop.seqId + 1, name: stop.name, latitude: stop.latitude, longitude: stop.longitude }))
                    ]
                }
            }

            return {
                ...state,
                stops: [
                    ...state.stops,
                    {
                        ...action.data,
                        seqId: state.stops.length + 1,
                    }
                ]
            }

        case 'EDIT_STOP_MYCHOIZE':
            return {
                ...state,
                stops: state.stops.map(function (stop, i) {
                    
                    if (i === action.id) {
                        return { seqId: stop.seqId, city: action.data.city, name: action.data.name, latitude: action.data.latitude, longitude: action.data.longitude };
                    }
                    else {
                        return stop;
                    }
                }
                )
            }

        case 'ADD_STOP_AT_INDEX_MYCHOIZE':
            
            return {
                ...state,
                stops: [
                    ...state.stops.slice(0, action.index),
                    {
                        seqId: action.index + 1,
                        ...action.data
                    },
                    ...state.stops.slice(action.index).map((stop) => ({ ...stop, seqId: stop.seqId + 1 }))
                ]
            }

        case 'PUSH_STOP_AT_INDEX_MYCHOIZE':
            newStops = state.stops;
            newStops[action.index] = action.data;
            
            return {
                ...state,
                stops: newStops
            }

        case 'REMOVE_LAST_STOP_MYCHOIZE':
            return {
                ...state,
                stops: [
                    ...state.stops.slice(0, state.stops.length - 1)
                ]
            }

        case 'UPDATE_PICKUP_LOC_MYCHOIZE':
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    loc: action.data.name,
                    locLat: action.data.locLat,
                    locLng: action.data.locLng
                }
            }

        case 'UPDATE_PICKUP_CITY_MYCHOIZE':
            return {
                ...state,
                pickup: {
                    ...state.pickup,
                    city: action.data.city,
                    cityLat: action.data.cityLat,
                    cityLng: action.data.cityLng,
                }
            }

        case 'UPDATE_DEST_LOC_MYCHOIZE':
            return {
                ...state,
                dest: {
                    ...state.dest,
                    loc: action.data.name,
                    locLat: action.data.locLat,
                    locLng: action.data.locLng
                }
            }

        case 'UPDATE_DEST_CITY_MYCHOIZE':
            return {
                ...state,
                dest: {
                    ...state.dest,
                    city: action.data.city,
                    cityLat: action.data.cityLat,
                    cityLng: action.data.cityLng,
                }
            }

        case 'UPDATE_CITY_OF_STOP_MYCHOIZE':
            return {
                ...state,
                stops: state.stops.map(
                    (stop, i) => i === action.stopId ? { ...stop, city: action.data.city, cityLat: action.data.cityLat, cityLng: action.data.cityLng } : stop
                )
            }
            
        default:
                return state
    
        }
    }
